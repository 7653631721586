import { ApolloError } from '@apollo/client/errors'
import { GetUserLocationDocument } from '@kijiji/generated/graphql-types'
import { type GetServerSideProps, type Redirect } from 'next'
import { v4 as generateUUID } from 'uuid'

import { getCMSContentModule } from '@/components/homepage/hero-cms-banner/getCMSContentModule'
import { HomepageInner } from '@/components/homepage/homepage-inner/HomepageInner'
import { getPopularNearYouLinks } from '@/components/homepage/popular-near-you/getPopularNearYouLinks'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { debugServerTime } from '@/domain/debugServerTiming'
import { initializePageServer } from '@/domain/initializePageServer'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { getMenuPrefetch } from '@/features/navigation/api/getMenuPrefetch'
import { addApolloState } from '@/lib/apollo/apolloClient'
import profile from '@/lib/metrics/profileWrapper'
import { type BasePageProps } from '@/pages/_app'

interface HomePageProps extends BasePageProps {
  correlationId: string
}

/**
 * The homepage could be called by accessing `/`
 * or paths like: `/h-<location-seo-name>/<locationId>
 *
 * When there are parameters on the homepage path, then the user's location
 * should be switched to that defined location
 */
const Home = ({ correlationId, userAgent = '' }: HomePageProps) => {
  return <HomepageInner correlationId={correlationId} userAgent={userAgent} />
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = profile<
  GetServerSideProps<HomePageProps>
>('homepage', 'getServerSideProps', async (context) => {
  const gSSPstart = Date.now()
  const { res } = context

  const {
    basePageProps,
    apolloClient,
    locale: { cookieLocale },
  } = await initializePageServer('homepage', context, [TRANSLATION_KEYS.HOME])

  const correlationId = generateUUID()

  /**
   * Queries we want to pre-fetch on the server to load asap in the page
   * */
  const location = apolloClient.readQuery({ query: GetUserLocationDocument })
  const locationId = location?.userLocation.id

  // Note: isValidLocation doesn't actually check if location is valid, just that the id is a number
  if (!isValidLocation(locationId)) {
    return { redirect: { destination: '/h-canada/0', permanent: false } }
  }

  const queryResults = await Promise.allSettled([
    profile(
      'homepage',
      'getMenuPrefetch',
      getMenuPrefetch
    )({ apolloClient, cookieLocale, locationId }),
    profile('homepage', 'getCMSContentModule', getCMSContentModule)({ apolloClient, locationId }),
    profile(
      'homepage',
      'getPopularNearYouLinks',
      getPopularNearYouLinks
    )({ apolloClient, locationId }),
  ])

  let locationErrorRedirect: { redirect: Redirect } | null = null

  queryResults.forEach((result) => {
    if (
      result.status !== 'rejected' ||
      !(result.reason instanceof ApolloError && result.reason.graphQLErrors.length > 0)
    ) {
      return
    }

    const errorCode = result.reason.graphQLErrors[0].extensions?.code
    const errorMessage = result.reason.graphQLErrors[0].message

    if (errorCode === 'LOCATION_ERROR' || errorMessage.includes('location')) {
      locationErrorRedirect = { redirect: { destination: '/h-canada/0', permanent: false } }
    }
  })

  if (locationErrorRedirect) {
    return locationErrorRedirect
  }

  const pageProps: HomePageProps = {
    correlationId,
    ...basePageProps,
  }

  const props = profile('homepage', 'addApolloState', addApolloState)(apolloClient, pageProps)
  debugServerTime('props', res, gSSPstart)

  return { props }
})

export default Home
